import { storeToRefs } from 'pinia'
import { navigateTo } from 'nuxt/app'
import { useUserStore } from '@/store/user'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { usePrescription } from '@/composables/usePrescription'
import { useCart } from '@/composables/useCart'
import { UserRole } from '@/types/jwt'
import { useLensUiStore } from '@/store/lensUi'
import { useRouter, useRuntimeConfig, useMagentoCookieState } from '#imports'
import { useVTO } from '~/composables/useVTO'
import { useProductFilter } from '~/composables/useProductFilter'

export const useLogin = () => {
  const router = useRouter()
  const userStore = useUserStore()
  const { currentRole } = storeToRefs(userStore)
  const { addEyeBotPrescriptionToCart } = useLensUiStore()
  const { setVTO, getVtoFromDatabase } = useVTO()
  const { setRecommendationSize } = useProductFilter()
  const { loadPrescriptionByUserId } = usePrescription()
  const { clear } = useCart()
  const siteConfigurationStore = useSiteConfigurationStore()
  const config = useRuntimeConfig()
  const initLoginData = async () => {
    const [, vtoDb] = await Promise.all([
      loadPrescriptionByUserId(),
      getVtoFromDatabase(),
      siteConfigurationStore.getSiteConfiguration(
        useMagentoCookieState().getStore()?.replaceAll('_', ' ') as string,
      ),
    ])
    if (vtoDb) {
      setVTO({ ...vtoDb })
    }
    setRecommendationSize()
    clear()
  }

  const initEyebotLoginData = async () => {
    clear()
    await Promise.all([
      siteConfigurationStore.getSiteConfiguration(
        useMagentoCookieState().getStore()?.replaceAll('_', ' ') as string,
      ),
    ])
    await addEyeBotPrescriptionToCart()
  }

  const getDefaultPath = (host: string) => {
    const urlParams = new URLSearchParams(window.location.search)
    const location = urlParams.get('redirect')
    if (location) {
      urlParams.delete('redirect')
      return host + `${location}?${urlParams.toString()}`
    }
    return host
  }

  // TODO: Refactor this to use `useRedirect` composable in base
  const handleRedirect = async () => {
    if (userStore.loggedIn) {
      if (userStore.roles) {
        if (currentRole.value === UserRole.AdminFinance) {
          navigateTo(`${config.public.employerDomain}/invoices`, {
            external: true,
          })
        } else if (currentRole.value === UserRole.AMPortalUser) {
          navigateTo(`${config.public.employerDomain}/`, {
            external: true,
          })
        } else if (currentRole.value === UserRole.Employer) {
          navigateTo(getDefaultPath(config.public.employerDomain), {
            external: true,
          })
        } else if (currentRole.value === UserRole.User) {
          await initEyebotLoginData()
          router.replace('/eyebot-welcome')
        } else {
          await initLoginData()
          router.replace('/select-lens-type')
        }
      }
    } else {
      userStore.logout()
      router.replace('/login')
    }
  }

  return {
    initLoginData,
    handleRedirect,
  }
}
